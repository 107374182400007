h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.faq {
  padding: 5rem 12%;
}
@media (max-width: 1200px) {
  .faq {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .faq {
    padding: 5rem 2rem;
  }
}
.faq .faq-heading {
  text-align: center;
  margin-bottom: 4rem;
}
.faq .faq-heading h1 {
  margin-bottom: 1rem;
  font-size: 4rem;
  color: #6ec1e4;
}
.faq .faq-heading h2 {
  font-size: 4rem;
}
.faq .lists .questions {
  margin-bottom: 3rem;
}
.faq .lists .questions .question {
  border: solid 0.5px #e1e1e1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 1s ease;
  padding: 2rem 3rem;
}
.faq .lists .questions .question.active {
  background-color: #008f34;
  color: white;
}
.faq .lists .questions .question.active svg {
  color: #217e37;
  border: none;
}
.faq .lists .questions .question h4 {
  font-size: 2.5rem;
  font-weight: 400;
}
@media (max-width: 500px) {
  .faq .lists .questions .question h4 {
    font-size: 2rem;
  }
}
.faq .lists .questions .question svg {
  font-size: 3.5rem;
  margin-left: 2rem;
  border-radius: 100%;
  border: 2px solid #e1e1e1;
  color: white;
  background-color: #6ec1e4;
}
.faq .lists .questions .answer {
  border: solid 0.5px rgb(187, 237, 246);
  max-height: 0px;
  overflow: hidden;
  transition: max-height 1s linear;
  width: 100%;
}
.faq .lists .questions .answer h4 {
  font-weight: 400;
  padding: 2rem 3rem;
  font-size: 2.5rem;
}
@media (max-width: 500px) {
  .faq .lists .questions .answer h4 {
    font-size: 2rem;
  }
}
.faq .lists .questions .answer.active {
  margin-bottom: 2rem;
  max-height: 60rem;
}/*# sourceMappingURL=FAQ.css.map */