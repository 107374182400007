h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.feedback {
  padding: 8rem 15%;
}
@media (max-width: 1200px) {
  .feedback {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .feedback {
    padding: 5rem 2rem;
  }
}
.feedback .heading h2 {
  color: #008f34;
}
.feedback .form {
  padding-top: 3rem;
}
.feedback .form form textarea {
  width: 100%;
  height: 20rem;
  font-size: 2.5rem;
  outline: none;
  padding: 1rem;
}
.feedback .form form input {
  margin-top: 2rem;
  font-size: 2.3rem;
  cursor: pointer;
  padding: 1rem;
  background-color: #008f34;
  color: white;
  outline: none;
  border: none;
}/*# sourceMappingURL=Feedback.css.map */