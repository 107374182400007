@import "../../common/variables/variables.scss";

.thank {
  text-align: center;
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: $main-l;
    font-size: 5rem;
    margin-bottom: 0.5rem;
  }

  .buttons {
    margin-top: 3rem;
    display: flex;
    gap: 2rem;
    a {
      color: $main-l;
      font-size: 2.5rem;
      padding: 0.5rem 1rem;
      transition: 0.5s all ease;
      &:hover {
        background-color: $main-l;
        color: white;
      }
    }
  }
}
