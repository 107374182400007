h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.navbar {
  position: fixed;
  width: 100%;
  top: -100%;
  left: 0;
  background-color: white;
  z-index: 1000;
  overflow: hidden;
  opacity: 0;
  transition: all ease 1s;
  border-bottom: #217e37 2px solid;
  padding: 0rem 7%;
}
@media (max-width: 1200px) {
  .navbar {
    padding: 0rem 3%;
  }
}
@media (max-width: 800px) {
  .navbar {
    padding: 0rem 2rem;
  }
}
.navbar .navbar_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 0 0rem;
}
@media (max-width: 900px) {
  .navbar .navbar_wrapper {
    padding: 0.5rem 0 0 0rem;
  }
}
.navbar .navbar_wrapper .navbar_logo img {
  width: 12rem;
}
@media (max-width: 800px) {
  .navbar .navbar_wrapper .navbar_logo img {
    width: 14rem;
  }
}
@media (max-width: 600px) {
  .navbar .navbar_wrapper .navbar_logo img {
    width: 10rem;
    height: 10rem;
  }
}
.navbar .navbar_wrapper .nav-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}
@media (max-width: 400px) {
  .navbar .navbar_wrapper .nav-content {
    max-width: 30rem;
  }
}
@media (max-width: 900px) {
  .navbar .navbar_wrapper .nav-content {
    align-items: center;
  }
}
.navbar .navbar_wrapper .nav-content .nav-content-u {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: #217e37;
}
@media (max-width: 700px) {
  .navbar .navbar_wrapper .nav-content .nav-content-u {
    gap: 1rem;
  }
}
.navbar .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper {
  font-size: 2.8rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.navbar .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper span {
  font-size: 3rem;
  display: flex;
}
@media (max-width: 850px) {
  .navbar .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper span {
    display: none;
  }
}
.navbar .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon {
  background-color: #217e37;
  position: relative;
  text-align: center;
  height: 38px;
  width: 38px;
  line-height: 38px;
  border-radius: 50%;
  margin-right: 20px;
  animation: pulse2 infinite 2s;
}
.navbar .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon svg {
  top: 7px;
  left: 8px;
  color: white;
  position: relative;
  display: block;
  font-size: 2.2rem;
}
@media (max-width: 450px) {
  .navbar .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon svg {
    top: 9px;
    left: 10px;
  }
}
.navbar .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon::after {
  content: "";
  height: 58px;
  width: 58px;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  left: -10px;
  animation: pulse2 infinite 2s;
}
@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 #008f34;
  }
  40% {
    box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
  }
}
.navbar .navbar_wrapper .nav-content .nav-content-u button {
  cursor: pointer;
  background-color: #217e37;
  padding: 1.4rem 3rem;
  border: none;
  color: white;
  font-size: 3rem;
  border-radius: 5rem;
}
@media (max-width: 500px) {
  .navbar .navbar_wrapper .nav-content .nav-content-u button {
    display: none;
    font-size: 2.5rem;
    border-radius: 2rem;
    padding: 1.4rem 2rem;
  }
}
.navbar .navbar_wrapper .nav-content .nav-content-u svg {
  font-size: 3rem;
  display: none;
}
@media (max-width: 900px) {
  .navbar .navbar_wrapper .nav-content .nav-content-u svg {
    display: block;
  }
}
@media (max-width: 900px) {
  .navbar .navbar_wrapper .nav-content .nav-content-d {
    display: none;
  }
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul {
  display: flex;
  list-style-type: none;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a {
  text-transform: uppercase;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .sublink {
  position: relative;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list {
  background-color: white;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 11;
  list-style-type: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  border-top: 2px solid #217e37;
  width: 24rem;
  gap: 0;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a {
  padding: 0.5rem 1rem;
  font-weight: 400;
  border-bottom: solid 1px #e9e9e9;
  transition: all ease 0.5s;
  text-transform: capitalize;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a:hover {
  padding-left: 4rem;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a:hover .icon {
  transform: scale(1);
  margin-right: 1rem;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a li {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1.5rem;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a li .icon {
  position: absolute;
  left: 20px;
  transform: scale(0);
  transition: all 0.5s ease 0s;
  font-size: 1.5rem;
  color: #217e37;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a li .icon.active {
  transform: scale(1);
  margin-right: 1rem;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a:hover li {
  color: #217e37;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a .nav-list.active {
  opacity: 1;
  visibility: visible;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a {
  font-weight: 550;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a li {
  font-size: 1.7rem;
  color: black;
  padding: 1.5rem;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul a li:hover {
  color: #217e37;
}
.navbar .navbar_wrapper .nav-content .nav-content-d ul .link.active li {
  color: #217e37;
}
.navbar .MeanNav .mean-nav-list {
  display: none;
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
}
@media (max-width: 900px) {
  .navbar .MeanNav .mean-nav-list {
    display: block;
  }
}
.navbar .MeanNav .mean-nav-list .link.active li {
  color: #217e37;
}
.navbar .MeanNav .mean-nav-list a li {
  padding: 1rem 0rem;
  color: black;
  font-weight: 600;
  border-top: dashed #ddd 1px;
}
.navbar .MeanNav .mean-nav-list a li .rightIcon {
  color: #217e37;
  padding-right: 1rem;
}
.navbar .MeanNav .mean-nav-list a .plus {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar .MeanNav .mean-nav-list a .plus div {
  color: black;
  font-weight: 600;
  font-size: inherit;
}
.navbar .MeanNav .mean-nav-list a .plus .plusicon {
  font-weight: 600;
  font-size: 2.5rem;
}
.navbar .MeanNav .mean-nav-list a .sub-mean {
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;
}
.navbar .MeanNav .mean-nav-list a .sub-mean a li {
  padding-left: 5rem;
}
.navbar .MeanNav .mean-nav-list a .sub-mean.servi {
  max-height: 60rem;
}
.navbar .MeanNav .mean-nav-list a .sub-mean.why {
  max-height: 60rem;
}
.navbar .MeanNav .mean-nav-list.active {
  max-height: 60rem;
}

.navbar.active {
  top: 0;
  opacity: inherit;
  overflow: visible;
}/*# sourceMappingURL=navbar.css.map */