h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.carpet-Landing-banner {
  height: 80vh;
  background-image: url(../DeepCleaning/dry1.jpg);
  background-size: cover;
}
@media (max-width: 1200px) {
  .carpet-Landing-banner {
    background-position: 70% 0%;
  }
}

.carpet-new-banner {
  height: 80vh;
  background-image: url(../../../../Assets/Images/Banner/carpet-new-banner.jpg);
  background-size: cover;
}
@media (max-width: 1200px) {
  .carpet-new-banner {
    background-position: 70% 0%;
  }
}
.carpet-new-banner .content {
  padding-top: 30rem;
  padding-left: 10%;
}
.carpet-new-banner .content h1 {
  color: #c2db5d;
  font-size: 5rem;
  text-shadow: 3px 3px 1px black;
}
@media (max-width: 500px) {
  .carpet-new-banner .content h1 {
    font-size: 4rem;
  }
}
@media (max-width: 800px) {
  .carpet-new-banner .content {
    padding-left: 2rem;
  }
}

.new-comment {
  background-color: #008f34;
  padding: 1rem 0;
  margin-bottom: 6rem;
}
.new-comment h2 {
  text-align: center;
  font-size: 3.5rem;
  color: white;
}
@media (max-width: 700px) {
  .new-comment h2 {
    font-size: 2.8rem;
  }
}

.carpet-land-content {
  padding: 5rem 13%;
}
@media (max-width: 1200px) {
  .carpet-land-content {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .carpet-land-content {
    padding: 5rem 2rem;
  }
}
.carpet-land-content .main-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 5rem;
}
.carpet-land-content .main-content .heading {
  width: 48%;
}
@media (max-width: 800px) {
  .carpet-land-content .main-content .heading {
    width: 100%;
  }
}
.carpet-land-content .main-content .heading h2 {
  font-size: 3.5rem;
  color: #008f34;
  margin-bottom: 0.5rem;
}
.carpet-land-content .main-content .heading h3 {
  color: black;
  margin-bottom: 0.5rem;
}
.carpet-land-content .main-content .heading p {
  margin-top: 1rem;
  text-align: justify;
}
.carpet-land-content .main-content .heading .buttons {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}
.carpet-land-content .main-content .heading .buttons a {
  font-size: 2.5rem;
  background-color: #008f34;
  padding: 0.8rem 1.3rem;
  color: white;
  cursor: pointer;
}
.carpet-land-content .main-content .img {
  width: 48%;
}
.carpet-land-content .main-content .img img {
  width: 100%;
}
@media (max-width: 800px) {
  .carpet-land-content .main-content .img {
    width: 100%;
  }
}
.carpet-land-content .dry-steam-container {
  margin-top: 2rem;
}
.carpet-land-content .dry-steam-container .DS-heading h3 {
  text-align: center;
  color: black;
  font-size: 3rem;
}
.carpet-land-content .dry-steam-container .dry-steam {
  display: flex;
  align-items: center;
  gap: 4rem;
  margin-top: 2rem;
}
@media (max-width: 700px) {
  .carpet-land-content .dry-steam-container .dry-steam {
    flex-direction: column;
  }
}
.carpet-land-content .dry-steam-container .dry-steam .dry {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  width: 49%;
}
@media (max-width: 700px) {
  .carpet-land-content .dry-steam-container .dry-steam .dry {
    width: 100%;
  }
}
.carpet-land-content .dry-steam-container .dry-steam .dry h4 {
  font-size: 2.5rem;
  text-align: center;
}
.carpet-land-content .dry-steam-container .dry-steam .dry img {
  max-width: 45rem;
  max-height: 30rem;
}

.sub-banner h2 {
  color: #008f34;
  text-align: center;
  font-size: 4rem;
  margin: 2rem 0;
}/*# sourceMappingURL=CarpetLanding.css.map */