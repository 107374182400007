h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.carpet-banner {
  background: url(../../../Assets/Images/Banner/banner-1.bk.jpg);
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 70vh;
}
@media (max-width: 800px) {
  .carpet-banner {
    background-position: 0% 0%;
  }
}
.carpet-banner .content-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.carpet-banner .content-wrapper .content {
  padding: 5rem 13%;
}
@media (max-width: 1200px) {
  .carpet-banner .content-wrapper .content {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .carpet-banner .content-wrapper .content {
    padding: 5rem 2rem;
  }
}
.carpet-banner .content-wrapper .content .info {
  animation: jump-shaking 2s ease-out;
}
.carpet-banner .content-wrapper .content .info h1 {
  margin-top: 18rem;
  color: #c2db5d;
  margin-bottom: 0.5rem;
}
.carpet-banner .content-wrapper .content .info h2 {
  font-size: 4rem;
  color: #008f34;
  margin-bottom: 0.5rem;
}
.carpet-banner .content-wrapper .content .info h3 {
  font-size: 4rem;
  color: white;
  background-color: #4a4a4c;
  padding: 0.5rem 1rem;
  width: -moz-max-content;
  width: max-content;
}
.carpet-banner .content-wrapper .content .info h3 span {
  font-weight: lighter;
  font-size: 1.2rem;
}
.carpet-banner .content-wrapper .content .info span {
  font-size: 2.5rem;
}
.carpet-banner .content-wrapper .content a {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  color: black;
  border: 1px solid rgb(1, 137, 177);
  border-radius: 4rem;
  width: -moz-fit-content;
  width: fit-content;
  animation: jump-shaking1 2s ease-out;
}
.carpet-banner .content-wrapper .content a:hover {
  animation-name: btn1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.carpet-banner .content-wrapper .content a span {
  font-size: 2.5rem;
  margin-right: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}
.carpet-banner .content-wrapper .content a img {
  width: 7rem;
  height: 5rem;
}

@keyframes jump-shaking {
  0% {
    transform: translateX(-20rem);
    opacity: 0.2;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}
@keyframes jump-shaking1 {
  0% {
    transform: translateY(10rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
.sub-banner img,
.last-banner img {
  width: 100%;
}

.carpet_service {
  padding: 5rem 13%;
}
@media (max-width: 1200px) {
  .carpet_service {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .carpet_service {
    padding: 5rem 2rem;
  }
}
.carpet_service .main_info {
  margin-bottom: 6rem;
}
.carpet_service .main_info h2 {
  text-align: center;
  color: #217e37;
  font-size: 4rem;
  margin-bottom: 4rem;
}
.carpet_service .main_info p {
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 1.8rem;
  margin-top: 1rem;
  line-height: 3rem;
}
.carpet_service .main_info .info {
  display: flex;
  align-items: center;
  gap: 3rem;
}
@media (max-width: 500px) {
  .carpet_service .main_info .info {
    margin-top: 3rem;
    flex-direction: column-reverse;
  }
}
.carpet_service .main_info .info .content {
  width: 70%;
}
@media (max-width: 500px) {
  .carpet_service .main_info .info .content {
    width: 100%;
  }
}
.carpet_service .main_info h3 {
  margin-top: 4rem;
  color: #c2db5d;
}
.carpet_service .main_info h4 {
  font-size: 3rem;
  color: black;
  margin-top: 1rem;
  font-weight: 400;
}
.carpet_service .main_info span {
  color: #217e37;
  text-decoration: underline;
  font-size: 2rem;
  line-height: 3rem;
  cursor: pointer;
}
.carpet_service .review_info {
  margin-bottom: 6rem;
}
.carpet_service .review_info h2 {
  text-align: center;
  color: #217e37;
  font-size: 4rem;
  margin-bottom: 1.5rem;
}
.carpet_service .review_info .review_videos {
  margin-top: 5rem;
  grid-gap: 2rem;
  display: grid;
  gap: 6rem;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
}
.carpet_service .review_info .review_videos iframe {
  width: 100%;
}
.carpet_service .cleaning_info {
  margin-bottom: 3rem;
}
.carpet_service .cleaning_info h2 {
  color: #008f34;
  text-align: center;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}
.carpet_service .cleaning_info h3 {
  text-align: center;
  color: black;
  font-size: 4rem;
}
.carpet_service .cleaning_info .imgs {
  margin-top: 3rem;
  margin-bottom: 3rem;
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
}
.carpet_service .cleaning_info .imgs .img-content img {
  width: 100%;
  padding: 2rem;
}
.carpet_service .cleaning_info a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4a4a4c;
  border-bottom: 5px solid #008f34;
}
@media (max-width: 650px) {
  .carpet_service .cleaning_info a {
    flex-direction: column;
  }
  .carpet_service .cleaning_info a img {
    margin-bottom: 2rem;
  }
  .carpet_service .cleaning_info a h4 {
    font-size: 3rem;
    text-align: center;
  }
}
.carpet_service .cleaning_info a h4 {
  padding: 4rem 2rem;
  cursor: pointer;
  font-size: 3.7rem;
  text-transform: uppercase;
  color: white;
}
.carpet_service .cleaning_info a img {
  width: 10rem;
  height: 7rem;
  margin-right: 5rem;
}

.solution {
  padding: 8rem 15%;
  background-color: #eee;
  margin-top: 2rem;
}
@media (max-width: 1200px) {
  .solution {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .solution {
    padding: 5rem 2rem;
  }
}
.solution .solution_heading {
  margin-bottom: 3rem;
}
.solution .solution_heading h2 {
  text-align: center;
  color: #008f34;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
}
.solution .solution_heading h3 {
  text-align: center;
  color: black;
  font-size: 4rem;
}
.solution .solution_content {
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}
.solution .solution_content .solution_box {
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.solution .solution_content .solution_box h2 {
  font-size: 2.3rem;
  margin-bottom: 2rem;
  color: #008f34;
}
.solution .solution_content .solution_box h3 {
  min-height: 5rem;
  font-size: 2rem;
  color: black;
  margin-bottom: 1rem;
}
.solution .solution_content .solution_box p {
  line-height: 3rem;
}
.solution .solution_content .solution_box p span {
  color: #008f34;
  font-size: 2rem;
  line-height: 3rem;
  cursor: pointer;
}
.solution .solution_content .solution_box img {
  width: 20rem;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.protected {
  padding: 8rem 10%;
}
@media (max-width: 1200px) {
  .protected {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .protected {
    padding: 5rem 2rem;
  }
}
.protected .heading {
  text-align: center;
  margin-bottom: 5rem;
}
.protected .heading h1 {
  color: #008f34;
  font-size: 3rem;
  margin-bottom: 2rem;
}
.protected .heading h2 {
  color: black;
  font-size: 3.5rem;
}
.protected .main-content .content-u {
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .protected .main-content .content-u {
    flex-direction: column;
  }
}
.protected .main-content .content-u .info {
  width: 50%;
}
@media (max-width: 750px) {
  .protected .main-content .content-u .info {
    width: 100%;
  }
}
.protected .main-content .content-u .info p {
  margin-bottom: 2rem;
  line-height: 3rem;
}
.protected .main-content .content-u .info h1 {
  line-height: 3.5rem;
  color: #6aa121;
  font-size: 3rem;
  margin-bottom: 2rem;
}
.protected .main-content .content-u .content {
  width: 50%;
}
@media (max-width: 750px) {
  .protected .main-content .content-u .content {
    width: 100%;
  }
}
.protected .main-content .content-u .content iframe {
  width: 100%;
  min-height: 20rem;
}
.protected .main-content .content-d {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .protected .main-content .content-d {
    flex-direction: column-reverse;
  }
}
.protected .main-content .content-d .content {
  width: 50%;
}
@media (max-width: 750px) {
  .protected .main-content .content-d .content {
    width: 100%;
  }
}
.protected .main-content .content-d .content img {
  width: 100%;
  padding: 2rem;
}
.protected .main-content .content-d .info {
  width: 50%;
}
@media (max-width: 750px) {
  .protected .main-content .content-d .info {
    width: 100%;
  }
}
.protected .main-content .content-d .info h1 {
  color: #008f34;
  font-size: 3rem;
  margin-bottom: 1rem;
}
.protected .main-content .content-d .info h2 {
  color: black;
  font-size: 3rem;
  margin-bottom: 1rem;
}
.protected .main-content .content-d .info p {
  margin-bottom: 2rem;
  line-height: 3rem;
}

.experience {
  padding: 8rem 15%;
  background: url(../../../Assets/Images/Banner/Capture.PNG);
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1200px) {
  .experience {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .experience {
    padding: 5rem 2rem;
  }
}
.experience .content-u {
  max-width: 90rem;
  margin-bottom: 15rem;
}
.experience .content-u h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 2rem;
}
.experience .content-u h2 {
  font-size: 4rem;
  font-weight: 400;
  color: white;
}
@media (max-width: 750px) {
  .experience .content-u {
    text-align: center;
    margin-bottom: 4rem;
  }
  .experience .content-u h1 {
    font-size: 3rem;
  }
  .experience .content-u h2 {
    font-size: 3.5rem;
  }
}
.experience .content-d {
  max-width: 90rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 750px) {
  .experience .content-d {
    flex-direction: column;
    text-align: center;
  }
}
.experience .content-d .img img {
  width: 16rem;
}
.experience .content-d .info h1 {
  color: black;
  font-size: 3rem;
  margin-bottom: 1rem;
}
.experience .content-d .info h2 {
  color: white;
  font-size: 3.5rem;
  margin-bottom: 1rem;
}
.experience .content-d .info p {
  color: white;
  font-size: 2.7rem;
  margin-bottom: 1rem;
}

.last-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.last-banner img {
  max-width: 80rem;
}

.benefits {
  padding: 5rem 10%;
  width: 100%;
  background-image: url(../../../Assets/Images/Banner/benifits-of-cleaning.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
@media (max-width: 1200px) {
  .benefits {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .benefits {
    padding: 5rem 2rem;
  }
}
.benefits .benefits-wrapper {
  padding-left: 40%;
}
@media (max-width: 1200px) {
  .benefits .benefits-wrapper {
    padding-left: 0;
  }
}
.benefits .benefits-wrapper .benefits-heading {
  margin-bottom: 4rem;
}
.benefits .benefits-wrapper .benefits-heading h2 {
  text-transform: uppercase;
  color: #217e37;
  font-size: 3rem;
  margin-bottom: 2rem;
}
.benefits .benefits-wrapper .benefits-heading h1 {
  text-transform: uppercase;
  color: black;
  font-size: 3rem;
}
.benefits .benefits-wrapper .benefits-content {
  grid-gap: 3rem;
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
}
@media (max-width: 1200px) {
  .benefits .benefits-wrapper .benefits-content {
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  }
}
.benefits .benefits-wrapper .benefits-content .benefit-box {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 1.5rem;
  min-height: 8rem;
  min-width: 8rem;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 4px rgba(3, 3, 3, 0.2);
  transition: all 0.5s ease;
}
.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-img:hover {
  transform: scale(1.2);
}
.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-img img {
  width: 4rem;
}
.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-info h4 {
  color: #008f34;
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-info p {
  font-size: 2rem;
}/*# sourceMappingURL=CarpetService.css.map */