h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.not-found {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 25rem;
  margin-bottom: 5rem;
}
.not-found .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found .content h1 {
  font-size: 15rem;
  background-image: url(./space3.avif);
  -webkit-background-clip: text;
          background-clip: text;
  margin-bottom: 2rem;
  -webkit-text-fill-color: transparent;
}
.not-found .content h3 {
  color: black;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.not-found .content a {
  font-size: 3rem;
}
.not-found .content a span {
  font-size: inherit;
  text-decoration: underline;
  color: #6ec1e4;
}

.navMain {
  background-color: #eee;
}/*# sourceMappingURL=NotFound.css.map */