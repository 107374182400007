h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.tubeQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
@media (max-width: 900px) {
  .tubeQ {
    display: none;
  }
}
.tubeQ iframe {
  width: 50rem;
  height: 35rem;
}
@media (max-width: 600px) {
  .tubeQ iframe {
    width: 40rem;
    height: 35rem;
  }
}
.tubeQ h2 {
  color: black;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}

.contactQ {
  padding: 2rem;
  margin-right: 10rem;
  background-color: #fff;
}
.contactQ .contact.content {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
@media (max-width: 750px) {
  .contactQ .contact.content {
    flex-direction: column;
  }
}
.contactQ .contact.content .contact.img {
  width: 50%;
}
.contactQ .contact.content .contact.img img {
  width: 100%;
}
@media (max-width: 750px) {
  .contactQ .contact.content .contact.img {
    display: none;
  }
}
@media (max-width: 750px) {
  .contactQ .contact.content .contact.info {
    width: 100%;
  }
}
.contactQ .contact.content .contact.info h2 {
  color: #008f34;
  font-size: 3.5rem;
  margin-bottom: 1rem;
}
.contactQ .contact.content .contact.info h1 {
  color: black;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}
.contactQ .contact.content .contact.info form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contactQ .contact.content .contact.info form input {
  width: 100%;
  background-color: white;
  height: 2rem;
  padding: 2.5rem 0;
  font-size: 2rem;
  padding-left: 1rem;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
}
.contactQ .contact.content .contact.info form textarea {
  padding: 1rem 0;
  background-color: white;
  height: 8rem;
  font-size: 2rem;
  padding-left: 1rem;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
}
.contactQ .contact.content .contact.info form button {
  width: 18rem;
  background-color: transparent;
  padding: 0;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(1, 137, 177);
  border-radius: 4rem;
  cursor: pointer;
}
.contactQ .contact.content .contact.info form button:hover {
  animation-name: btn1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.contactQ .contact.content .contact.info form button span {
  font-size: 2.2rem;
  margin-right: 2rem;
  margin-left: 1rem;
  color: black;
}
.contactQ .contact.content .contact.info form button img {
  width: 7rem;
  height: 5rem;
}
.contactQ .contact.content .contact.info form .name {
  display: flex;
  gap: 2rem;
}
.contactQ .contact.content .contact.info form .name .block {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.g-recaptcha {
  transform: scale(0.6);
  transform-origin: 0 0;
}/*# sourceMappingURL=Quote.css.map */