h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.pressure-banner {
  height: 80vh;
  background-size: cover;
  background-position: center;
}
.pressure-banner .container {
  padding-top: 30rem;
  padding-left: 10rem;
}
@media (max-width: 800px) {
  .pressure-banner .container {
    padding-left: 3rem;
    padding-top: 27rem;
  }
}
.pressure-banner .container h1 {
  font-size: 4.5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
}
@media (max-width: 800px) {
  .pressure-banner .container h1 {
    font-size: 3.2rem;
  }
}
.pressure-banner .container h3 {
  margin-top: 1rem;
  font-size: 3.5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
  margin-bottom: 2rem;
}
@media (max-width: 800px) {
  .pressure-banner .container h3 {
    font-size: 2.5rem;
  }
}
.pressure-banner .container .rug-contact {
  display: flex;
  align-items: center;
  color: white;
  border: 1px solid rgb(1, 137, 177);
  border-radius: 4rem;
  width: -moz-fit-content;
  width: fit-content;
}
.pressure-banner .container .rug-contact:hover {
  animation-name: btn1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.pressure-banner .container .rug-contact span {
  font-size: 2.5rem;
  margin-right: 2rem;
  margin-left: 1rem;
  font-weight: 600;
  text-shadow: 2px 2px 2px black;
}
.pressure-banner .container .rug-contact img {
  width: 7rem;
  height: 5rem;
}

.pressure {
  margin-top: 5rem;
}
.pressure .pressure-content {
  padding: 5rem 13%;
  margin-bottom: -8rem;
}
@media (max-width: 1200px) {
  .pressure .pressure-content {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .pressure .pressure-content {
    padding: 5rem 2rem;
  }
}
.pressure .pressure-content .main-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 5rem;
}
.pressure .pressure-content .main-content .heading {
  width: 48%;
}
@media (max-width: 800px) {
  .pressure .pressure-content .main-content .heading {
    width: 100%;
  }
}
.pressure .pressure-content .main-content .heading h2 {
  font-size: 3.5rem;
  color: #008f34;
  margin-bottom: 0.5rem;
}
.pressure .pressure-content .main-content .heading h3 {
  color: black;
  margin-bottom: 0.5rem;
}
.pressure .pressure-content .main-content .heading p {
  margin-top: 1rem;
  text-align: justify;
}
.pressure .pressure-content .main-content .heading .buttons {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}
.pressure .pressure-content .main-content .heading .buttons a {
  font-size: 2.5rem;
  background-color: #008f34;
  padding: 0.8rem 1.3rem;
  color: white;
  cursor: pointer;
  transition: all ease 0.5s;
}
.pressure .pressure-content .main-content .heading .buttons a:hover {
  background-color: greenyellow;
}
.pressure .pressure-content .main-content .img {
  width: 48%;
}
@media (max-width: 800px) {
  .pressure .pressure-content .main-content .img {
    width: 100%;
  }
}/*# sourceMappingURL=Pressure.css.map */