h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.terms.section {
  padding: 5rem 12%;
}
@media (max-width: 1200px) {
  .terms.section {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .terms.section {
    padding: 5rem 2rem;
  }
}
.terms.section .terms.heading {
  margin-bottom: 2rem;
}
.terms.section .terms.heading h2 {
  color: #217e37;
  font-size: 4rem;
}
.terms.section .terms.info {
  display: flex;
  flex-direction: column;
}
.terms.section .terms.info p {
  margin-bottom: 1.8rem;
  line-height: 3rem;
}
.terms.section .terms.info p span {
  color: #217e37;
  font-size: inherit;
  cursor: pointer;
}/*# sourceMappingURL=Terms.css.map */