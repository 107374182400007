h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.home {
  width: 100%;
  height: 60rem;
  background-image: linear-gradient(90deg, rgba(71, 71, 71, 0.75), transparent), url(../../../Assets/Images/Banner/home-banner.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 500px) {
  .home {
    background-image: linear-gradient(90deg, rgba(71, 71, 71, 0.75), transparent), url(../../../Assets/Images/Banner/home-banner-mob-min.jpg);
  }
}
.home .main_banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15%;
  padding-top: 15rem;
}
@media (max-width: 800px) {
  .home .main_banner {
    padding-left: 2rem;
  }
}
.home .main_banner .main_banner_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.home .main_banner .main_banner_content button {
  align-self: flex-start;
  font-size: 1.9rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: black;
  color: #ffcf60;
}
.home .main_banner .main_banner_content h1 {
  font-size: 5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
}
@media (max-width: 500px) {
  .home .main_banner .main_banner_content h1 {
    font-size: 4rem;
  }
}
.home .main_banner .main_banner_content h2 {
  color: white;
  font-size: 2.5rem;
  text-shadow: 3px 3px 1px black;
}
@media (max-width: 500px) {
  .home .main_banner .main_banner_content h2 {
    display: none;
  }
}
.home .main_banner .main_banner_content h3 {
  display: none;
  font-size: 2.5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
}
.home .main_banner .main_banner_content h3 span {
  font-size: inherit;
  color: #6ec1e4;
}
@media (max-width: 500px) {
  .home .main_banner .main_banner_content h3 {
    display: block;
  }
}
.home .main_banner .main_banner_content p {
  font-size: 3.5rem;
  text-shadow: 2px 2px 1px black;
  color: white;
}
.home .main_banner .main_banner_content p {
  font-size: 3rem;
}
@keyframes jump-shaking {
  0% {
    transform: translateX(-20rem);
    opacity: 0.2;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

.banner-strip {
  margin: -30px 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #008f34;
  color: white;
  text-align: center;
}
@media (max-width: 1200px) {
  .banner-strip {
    margin: -30px 7%;
  }
}
@media (max-width: 800px) {
  .banner-strip {
    margin: -30px 2rem;
    display: none;
  }
}
@media (max-width: 750px) {
  .banner-strip h2 {
    font-size: 1.7rem;
  }
}
.banner-strip h2 span {
  font-size: inherit;
  text-decoration: underline;
}

.pick_up.section .pick_up.heading {
  text-align: center;
  margin-bottom: 4rem;
  padding: 2rem 1rem;
}
.pick_up.section .pick_up.heading h2 {
  color: #217e37;
  font-size: 4rem;
}
.pick_up.section .pick_up.content {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .pick_up.section .pick_up.content {
    flex-direction: column;
  }
}
.pick_up.section .pick_up.content .pick_up.info {
  width: 125%;
}
@media (max-width: 750px) {
  .pick_up.section .pick_up.content .pick_up.info {
    width: 100%;
  }
}
.pick_up.section .pick_up.content .pick_up.info h5 {
  color: black;
  font-size: 3.5rem;
  margin-bottom: 1rem;
}
.pick_up.section .pick_up.content .pick_up.info p {
  font-size: 2rem;
  line-height: 3.2rem;
  margin-bottom: 1rem;
}
.pick_up.section .pick_up.content .pick_up.img img {
  width: 100%;
  border-radius: 0.5rem;
}
@media (max-width: 750px) {
  .pick_up.section .pick_up.content .pick_up.img img {
    width: 38rem;
    height: 40rem;
  }
}

.lease-banner {
  padding: 8rem 15%;
  background: url(../../../Assets/Images/Banner/3d-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
}
@media (max-width: 1200px) {
  .lease-banner {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .lease-banner {
    padding: 5rem 2rem;
  }
}
.lease-banner .wrapper {
  display: flex;
  gap: 3rem;
  align-items: center;
}
@media (max-width: 600px) {
  .lease-banner .wrapper {
    flex-direction: column;
  }
}
.lease-banner .wrapper .img img {
  width: 100%;
}
.lease-banner .wrapper .content {
  max-width: 55rem;
}
.lease-banner .wrapper .content h3 {
  font-size: 4rem;
  margin-bottom: 0.5rem;
}
.lease-banner .wrapper .content h2 {
  color: white;
  margin-bottom: 0.5rem;
}
.lease-banner .wrapper .content p {
  color: white;
  font-size: 2.5rem;
}
@media (max-width: 700px) {
  .lease-banner .wrapper .content {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

.wet-page {
  padding: 5rem 10%;
}
@media (max-width: 1200px) {
  .wet-page {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .wet-page {
    padding: 5rem 2rem;
  }
}
.wet-page .heading {
  margin-bottom: 2rem;
}
.wet-page .heading h2 {
  font-size: 4rem;
}
.wet-page .content {
  display: flex;
  gap: 4rem;
}
@media (max-width: 750px) {
  .wet-page .content {
    align-items: center;
    flex-direction: column;
    gap: 2rem;
  }
}
.wet-page .content .img {
  width: 40%;
}
.wet-page .content .img img {
  width: 100%;
}
@media (max-width: 750px) {
  .wet-page .content .img {
    width: 100%;
  }
}
.wet-page .content .info {
  width: 60%;
}
@media (max-width: 750px) {
  .wet-page .content .info {
    width: 100%;
  }
}
.wet-page .content .info img {
  width: 100%;
}
.wet-page .content .info p {
  margin-bottom: 1rem;
}

.methods {
  padding: 5rem 10%;
  background-color: #eee;
}
@media (max-width: 1200px) {
  .methods {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .methods {
    padding: 5rem 2rem;
  }
}
.methods .methods-heading h2 {
  text-align: center;
  color: #008f34;
  padding: 0.5rem 0rem;
  margin-bottom: 3rem;
  font-size: 3.5rem;
}
.methods .methods-content .methods-info {
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}
.methods .methods-content .methods-info .methods-type {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
}
.methods .methods-content .methods-info .methods-type .methods-type-heading {
  padding-top: 5rem;
}
.methods .methods-content .methods-info .methods-type .methods-type-heading h2 {
  font-size: 2.5rem;
  text-transform: capitalize;
  color: #008f34;
  text-align: center;
}
.methods .methods-content .methods-info .methods-type .methods-type-img {
  padding: 4rem;
}
.methods .methods-content .methods-info .methods-type .methods-type-img img {
  cursor: pointer;
  border-radius: 0.5rem;
  width: 100%;
  transition: 0.2s linear;
  max-height: 36rem;
}
@media (max-width: 800px) {
  .methods .methods-content .methods-info .methods-type .methods-type-img img {
    height: 40rem;
  }
}
.methods .methods-content .methods-info .methods-type .methods-type-img img:hover {
  transform: scale(1.1);
}
.methods .methods-content .methods-info .methods-type .methods-type-info {
  text-align: justify;
  cursor: pointer;
  padding: 0rem 2rem 2rem 2rem;
  font-size: 2rem;
  line-height: 3rem;
  color: #686868;
}
.methods .methods-content .methods-info .methods-type .methods-type-info span {
  color: #008f34;
  font-size: 2rem;
  line-height: 3rem;
  cursor: pointer;
}
.methods .methods-content .methods-info .methods-type .methods-type-info a span {
  font-size: 2rem;
  line-height: 3rem;
  color: #008f34;
}

.types.section .types.heading {
  margin-bottom: 2rem;
}
.types.section .types.content {
  display: flex;
  gap: 5rem;
  align-items: flex-start;
}
@media (max-width: 750px) {
  .types.section .types.content {
    align-items: center;
    flex-direction: column;
  }
}
.types.section .types.content .types.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 50%;
}
.types.section .types.content .types.info h2 {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 3.5rem;
}
.types.section .types.content .types.info p {
  font-size: 2rem;
  line-height: 3.5rem;
}
.types.section .types.content .types.info img {
  max-width: 50rem;
}
@media (max-width: 750px) {
  .types.section .types.content .types.info img {
    width: 40rem;
    height: 30rem;
  }
}
@media (max-width: 750px) {
  .types.section .types.content .types.info {
    width: 100%;
  }
}
.types.section .types.content .types.img {
  width: 50%;
}
.types.section .types.content .types.img .tube {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media (max-width: 900px) {
  .types.section .types.content .types.img .tube {
    display: flex;
  }
}
.types.section .types.content .types.img .tube iframe {
  width: 45rem;
  height: 35rem;
}
@media (max-width: 600px) {
  .types.section .types.content .types.img .tube iframe {
    width: 40rem;
    height: 35rem;
  }
}
.types.section .types.content .types.img .tube h2 {
  text-align: center;
  color: black;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}
@media (max-width: 750px) {
  .types.section .types.content .types.img {
    width: 100%;
  }
}

.review.section {
  padding: 5rem 10%;
}
@media (max-width: 1200px) {
  .review.section {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .review.section {
    padding: 5rem 2rem;
  }
}

.choose_us.section .choose_us.heading {
  margin-bottom: 4rem;
}
.choose_us.section .choose_us.heading h2 {
  text-align: center;
  padding: 2rem 1rem;
  font-size: 3rem;
}
.choose_us.section .choose_us.content {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .choose_us.section .choose_us.content {
    flex-direction: column;
  }
}
.choose_us.section .choose_us.content .choose_us.info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}
.choose_us.section .choose_us.content .choose_us.info ul {
  list-style: none;
}
.choose_us.section .choose_us.content .choose_us.info ul li {
  text-align: start;
  display: flex;
  align-items: center;
  color: #948e8e;
  font-weight: 600;
  font-size: 2.8rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 500px) {
  .choose_us.section .choose_us.content .choose_us.info ul li {
    font-size: 2rem;
  }
}
.choose_us.section .choose_us.content .choose_us.info ul li img {
  width: 5rem;
  color: #008f34;
  margin-right: 1rem;
}
@media (max-width: 800px) {
  .choose_us.section .choose_us.content .choose_us.info ul li img {
    height: 5rem;
  }
}
.choose_us.section .choose_us.content .choose_us.info ul li svg {
  font-size: 3rem;
  color: #008f34;
  margin-right: 1rem;
}
.choose_us.section .choose_us.content .choose_us.img img {
  width: 100%;
}

.extra .extra1 {
  background-color: #eee;
  padding: 5rem 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .extra .extra1 {
    padding: 5rem 15%;
  }
}
@media (max-width: 800px) {
  .extra .extra1 {
    padding: 5rem 2rem;
  }
}
.extra .extra1 img {
  max-width: 15rem;
}
@media (max-width: 800px) {
  .extra .extra1 img {
    height: 15rem;
  }
}
.extra .extra1 h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 3rem;
  color: #000;
}
.extra .extra1 p {
  text-align: center;
}
.extra .extra2 {
  background: url(../../../Assets/Images/Banner/pet-banner.webp);
}
.extra .extra2 .heading {
  margin-bottom: 3rem;
}
.extra .extra2 .heading h1 {
  color: #000;
}
.extra .extra2 .extra2-wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 700px) {
  .extra .extra2 .extra2-wrapper {
    justify-content: center;
    flex-direction: column-reverse;
  }
}
.extra .extra2 .extra2-wrapper .extra2-img {
  display: flex;
  justify-content: center;
  width: 170%;
  padding: 1rem;
}
@media (max-width: 700px) {
  .extra .extra2 .extra2-wrapper .extra2-img {
    width: 100%;
  }
}
.extra .extra2 .extra2-wrapper .extra2-img img {
  max-width: 40rem;
}
@media (max-width: 750px) {
  .extra .extra2 .extra2-wrapper .extra2-img img {
    width: 40rem;
    height: 37rem;
  }
}
.extra .extra2 .extra2-wrapper .extra2-info p {
  font-size: 2.2rem;
  line-height: 4rem;
}
.extra .extra2 .extra2-wrapper .extra2-info p strong {
  font-size: inherit;
}

.work.section .work.heading h2 {
  text-align: center;
  color: #008f34;
  font-size: 3.5rem;
}

.frequency-wrapper .frequency {
  background-image: url(../../../Assets/Images/Banner/map-banner.webp);
  padding: 5rem 15%;
  text-align: center;
}
@media (max-width: 1200px) {
  .frequency-wrapper .frequency {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .frequency-wrapper .frequency {
    padding: 5rem 2rem;
  }
}
.frequency-wrapper .frequency h1 {
  color: #000;
  margin-bottom: 2rem;
}
.frequency-wrapper .frequency p {
  margin-bottom: 4rem;
}
.frequency-wrapper .frequency .frequency-content .frequency-box img {
  width: 100%;
  margin-bottom: 4rem;
}
@media (max-width: 800px) {
  .frequency-wrapper .frequency .frequency-content .frequency-box img {
    height: 15rem;
  }
}
@media (max-width: 650px) {
  .frequency-wrapper .frequency .frequency-content .frequency-box img {
    height: 13rem;
  }
}
@media (max-width: 500px) {
  .frequency-wrapper .frequency .frequency-content .frequency-box img {
    height: 12rem;
  }
}
.frequency-wrapper .frequency .frequency-content .frequency-box h2 {
  color: #008f34;
}

.protect.section {
  padding: 5rem 13%;
  background-image: url(../../../Assets/Images/Banner/pet-banner.webp);
}
@media (max-width: 1200px) {
  .protect.section {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .protect.section {
    padding: 5rem 2rem;
  }
}
.protect.section .protect.content {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .protect.section .protect.content {
    flex-direction: column;
  }
}
.protect.section .protect.content .protect.info {
  width: 90%;
}
@media (max-width: 750px) {
  .protect.section .protect.content .protect.info {
    width: 100%;
  }
}
.protect.section .protect.content .protect.info h2 {
  margin-bottom: 1rem;
  color: #008f34;
  font-size: 4rem;
}
.protect.section .protect.content .protect.info h3 {
  margin-bottom: 2rem;
  color: black;
  font-size: 3rem;
}
.protect.section .protect.content .protect.info p {
  font-size: 2rem;
  line-height: 3.5rem;
}
.protect.section .protect.content .protect.img img {
  width: 100%;
  border-radius: 0.5rem;
  border-radius: 0.5rem;
}
@media (max-width: 750px) {
  .protect.section .protect.content .protect.img img {
    width: 50rem;
    height: 30rem;
  }
}
@media (max-width: 450px) {
  .protect.section .protect.content .protect.img img {
    width: 40rem;
    height: 25rem;
  }
}

.copyright.section {
  background-color: #eee;
}

.fixed-icon {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 200;
}

@media (max-width: 600px) {
  .fixed-icon {
    right: 10px;
    bottom: 10px;
  }
}
.Call-now-button {
  background-color: none;
  background-color: #045ad2;
  margin-top: 15px;
  border-radius: 5px;
  z-index: 10;
}

.Call-now-button .phone-icon1 {
  font-size: 3.4rem;
  margin: 10px;
  cursor: pointer;
  color: white;
  position: relative;
  z-index: 10;
}

.section {
  padding: 5rem 13%;
}
@media (max-width: 1200px) {
  .section {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .section {
    padding: 5rem 2rem;
  }
}

.fixed-top {
  position: fixed;
  background-color: #008f34;
  bottom: 2%;
  right: 2%;
  padding: 1.5rem;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.5s ease;
  cursor: pointer;
}
.fixed-top svg {
  color: white;
  width: 2rem;
  height: 2rem;
}
.fixed-top.active {
  opacity: 1;
}

.whatsapp-button {
  position: fixed;
  bottom: 7%;
  padding: 0.5rem 0.7rem;
  background-color: #25d365;
  border-radius: 5px;
  z-index: 10;
  left: 1%;
}
@media (max-width: 800px) {
  .whatsapp-button {
    left: 10%;
  }
}
.whatsapp-button svg {
  font-size: 4rem;
  color: white;
}

.Appointment-fixed {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1%;
  z-index: 10;
}
.Appointment-fixed a {
  padding: 0.7rem 0;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  background-color: #045ad2;
  margin: 0rem 5%;
  font-size: 2.5rem;
  color: white;
}
@media (max-width: 700px) {
  .Appointment-fixed {
    display: flex;
  }
}/*# sourceMappingURL=Home.css.map */