h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.homeL {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 900px) {
  .homeL {
    height: 80vh;
    background-position: 40% 80%;
  }
}
.homeL .main_banner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15%;
  padding-top: 20rem;
  animation: jump-shaking 2s ease-out;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .homeL .main_banner {
    padding-left: 2rem;
  }
}
@media (max-width: 900px) {
  .homeL .main_banner .quote {
    display: none;
  }
}
.homeL .main_banner .main_banner_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.homeL .main_banner .main_banner_content button {
  align-self: flex-start;
  font-size: 1.9rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: black;
  color: #ffcf60;
}
.homeL .main_banner .main_banner_content h1 {
  font-size: 5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
}
.homeL .main_banner .main_banner_content h1 span {
  border-bottom: 0.5rem solid white;
  box-shadow: 0px 3px black;
  font-size: inherit;
}
@media (max-width: 500px) {
  .homeL .main_banner .main_banner_content h1 {
    font-size: 3.5rem;
  }
  .homeL .main_banner .main_banner_content h1 span {
    border-bottom: 0.2rem solid white;
    box-shadow: 0px 2px black;
    font-size: inherit;
  }
}
.homeL .main_banner .main_banner_content h3 {
  font-size: 5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
}
@media (max-width: 500px) {
  .homeL .main_banner .main_banner_content h3 {
    font-size: 4rem;
  }
}
.homeL .main_banner .main_banner_content p {
  font-size: 3.5rem;
  text-shadow: 2px 2px 1px black;
  color: white;
}
.homeL .main_banner .main_banner_content p {
  font-size: 3rem;
}
.homeL .main_banner .main_banner_content .rug-contact {
  display: flex;
  align-items: center;
  color: white;
  border: 1px solid rgb(1, 137, 177);
  border-radius: 4rem;
  width: -moz-fit-content;
  width: fit-content;
}
.homeL .main_banner .main_banner_content .rug-contact:hover {
  animation-name: btn1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.homeL .main_banner .main_banner_content .rug-contact span {
  font-size: 2.5rem;
  margin-right: 2rem;
  margin-left: 1rem;
  font-weight: 600;
  text-shadow: 2px 2px 2px black;
}
.homeL .main_banner .main_banner_content .rug-contact img {
  width: 7rem;
  height: 5rem;
}
.homeL .main_banner .main_banner_content .carpet-contact {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 3rem;
  border-radius: 1rem;
  animation: background 1.5s infinite ease;
}
.homeL .main_banner .main_banner_content .carpet-contact .info {
  text-align: center;
}
.homeL .main_banner .main_banner_content .carpet-contact .info span {
  color: white;
  font-size: 2.5rem;
}
.homeL .main_banner .main_banner_content .carpet-contact .info h4 {
  font-size: 3.5rem;
  color: white;
}
@keyframes background {
  0% {
    background-color: blueviolet;
  }
  50% {
    background-color: rgb(174, 128, 217);
  }
  100% {
    background-color: blueviolet;
  }
}
@keyframes colorChange {
  0% {
    color: white;
  }
  50% {
    color: black;
  }
  100% {
    color: white;
  }
}
@keyframes sizeChange {
  0% {
    font-size: 2.5rem;
  }
  50% {
    font-size: 4rem;
  }
  100% {
    font-size: 2.5rem;
  }
}
.homeL .main_banner .main_banner_content .carpet-contact .icon svg {
  color: white;
  font-size: 4rem;
  animation: sizeChange 1.5s infinite ease;
}
@keyframes jump-shaking {
  0% {
    transform: translateX(-20rem);
    opacity: 0.2;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}
.homeL .pick_up.section .pick_up.heading {
  text-align: center;
  margin-bottom: 4rem;
  padding: 2rem 1rem;
}
.homeL .pick_up.section .pick_up.heading h2 {
  color: #217e37;
  font-size: 4rem;
}
.homeL .pick_up.section .pick_up.content {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .homeL .pick_up.section .pick_up.content {
    flex-direction: column;
  }
}
.homeL .pick_up.section .pick_up.content .pick_up.info {
  width: 125%;
}
@media (max-width: 750px) {
  .homeL .pick_up.section .pick_up.content .pick_up.info {
    width: 100%;
  }
}
.homeL .pick_up.section .pick_up.content .pick_up.info h5 {
  color: black;
  font-size: 3.5rem;
  margin-bottom: 1rem;
}
.homeL .pick_up.section .pick_up.content .pick_up.info p {
  font-size: 2rem;
  line-height: 3.2rem;
  margin-bottom: 1rem;
}
.homeL .pick_up.section .pick_up.content .pick_up.img img {
  width: 100%;
  border-radius: 0.5rem;
}
.homeL .methods {
  padding: 5rem 10%;
  background-color: #eee;
}
@media (max-width: 1200px) {
  .homeL .methods {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .homeL .methods {
    padding: 5rem 2rem;
  }
}
.homeL .methods .methods-heading h2 {
  text-align: center;
  color: #008f34;
  padding: 0.5rem 0rem;
  margin-bottom: 3rem;
  font-size: 3.5rem;
}
.homeL .methods .methods-content .methods-info {
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}
.homeL .methods .methods-content .methods-info .methods-type {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 30rem;
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-heading {
  padding-top: 5rem;
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-heading h2 {
  font-size: 2.5rem;
  text-transform: capitalize;
  color: #008f34;
  text-align: center;
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-img {
  padding: 4rem;
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-img img {
  cursor: pointer;
  border-radius: 0.5rem;
  width: 100%;
  transition: 0.2s linear;
  max-height: 36rem;
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-img img:hover {
  transform: scale(1.1);
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-info {
  text-align: justify;
  cursor: pointer;
  padding: 0rem 2rem 2rem 2rem;
  font-size: 2rem;
  line-height: 3rem;
  color: #686868;
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-info span {
  color: #008f34;
  font-size: 2rem;
  line-height: 3rem;
  cursor: pointer;
}
.homeL .methods .methods-content .methods-info .methods-type .methods-type-info a span {
  font-size: 2rem;
  line-height: 3rem;
  color: #008f34;
}
.homeL .types.section .types.heading {
  margin-bottom: 2rem;
}
.homeL .types.section .types.content {
  display: flex;
  gap: 5rem;
}
@media (max-width: 750px) {
  .homeL .types.section .types.content {
    align-items: center;
    flex-direction: column;
  }
}
.homeL .types.section .types.content .types.info h2 {
  margin-bottom: 1rem;
  text-align: center;
}
.homeL .types.section .types.content .types.info p {
  font-size: 2rem;
  line-height: 3.5rem;
}
@media (max-width: 750px) {
  .homeL .types.section .types.content .types.info {
    width: 100%;
  }
}
.homeL .types.section .types.content .types.img {
  width: 70%;
}
@media (max-width: 750px) {
  .homeL .types.section .types.content .types.img {
    width: 100%;
  }
}
.homeL .types.section .types.content .types.img img {
  width: 100%;
}
.homeL .review.section {
  padding: 5rem 10%;
}
@media (max-width: 1200px) {
  .homeL .review.section {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .homeL .review.section {
    padding: 5rem 2rem;
  }
}
.homeL .choose_us.section .choose_us.heading {
  margin-bottom: 4rem;
}
.homeL .choose_us.section .choose_us.heading h2 {
  text-align: center;
  padding: 2rem 1rem;
  font-size: 3rem;
}
.homeL .choose_us.section .choose_us.content {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .homeL .choose_us.section .choose_us.content {
    flex-direction: column;
  }
}
.homeL .choose_us.section .choose_us.content .choose_us.info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}
.homeL .choose_us.section .choose_us.content .choose_us.info ul {
  list-style: none;
}
.homeL .choose_us.section .choose_us.content .choose_us.info ul li {
  text-align: start;
  display: flex;
  align-items: center;
  color: #948e8e;
  font-weight: 600;
  font-size: 2.8rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 500px) {
  .homeL .choose_us.section .choose_us.content .choose_us.info ul li {
    font-size: 2rem;
  }
}
.homeL .choose_us.section .choose_us.content .choose_us.info ul li img {
  width: 5rem;
  color: #008f34;
  margin-right: 1rem;
}
.homeL .choose_us.section .choose_us.content .choose_us.info ul li svg {
  font-size: 3rem;
  color: #008f34;
  margin-right: 1rem;
}
.homeL .choose_us.section .choose_us.content .choose_us.img img {
  width: 100%;
}
.homeL .extra .extra1 {
  background-color: #eee;
  padding: 5rem 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1200px) {
  .homeL .extra .extra1 {
    padding: 5rem 15%;
  }
}
@media (max-width: 800px) {
  .homeL .extra .extra1 {
    padding: 5rem 2rem;
  }
}
.homeL .extra .extra1 img {
  max-width: 15rem;
}
.homeL .extra .extra1 h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 3rem;
  color: #000;
}
.homeL .extra .extra1 p {
  text-align: center;
}
.homeL .extra .extra2 {
  background: url(../../../../Assets/Images/Banner/pet-banner.webp);
}
.homeL .extra .extra2 .heading {
  margin-bottom: 3rem;
}
.homeL .extra .extra2 .heading h1 {
  color: #000;
}
.homeL .extra .extra2 .extra2-wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 700px) {
  .homeL .extra .extra2 .extra2-wrapper {
    justify-content: center;
    flex-direction: column-reverse;
  }
}
.homeL .extra .extra2 .extra2-wrapper .extra2-img {
  display: flex;
  justify-content: center;
  width: 170%;
  padding: 1rem;
}
@media (max-width: 700px) {
  .homeL .extra .extra2 .extra2-wrapper .extra2-img {
    width: 100%;
  }
}
.homeL .extra .extra2 .extra2-wrapper .extra2-img img {
  max-width: 40rem;
}
.homeL .extra .extra2 .extra2-wrapper .extra2-info p {
  font-size: 2.2rem;
  line-height: 4rem;
}
.homeL .extra .extra2 .extra2-wrapper .extra2-info p strong {
  font-size: inherit;
}
.homeL .work.section .work.heading h2 {
  text-align: center;
  color: #008f34;
  font-size: 3.5rem;
}
.homeL .work.section .work.content .worksD .heading {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 2rem;
  color: black;
}
.homeL .work.section .work.content .worksD .works-content {
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}
.homeL .work.section .work.content .worksD .works-content img {
  width: 100%;
}
.homeL .work.section .work.content .worksD a {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeL .work.section .work.content .worksD a .more-images {
  display: flex;
  align-items: center;
  background-color: #008f34;
  padding: 2rem 2rem;
  border-bottom-left-radius: 3rem;
  border-top-right-radius: 3rem;
  text-align: center;
}
.homeL .work.section .work.content .worksD a .more-images h1 {
  font-size: 3rem;
  color: white;
}
@media (max-width: 700px) {
  .homeL .work.section .work.content .worksD a .more-images h1 {
    font-size: 2rem;
  }
}
.homeL .work.section .work.content .worksD a .more-images svg {
  color: white;
  font-size: 3rem;
  padding: 0rem 2rem;
}
.homeL .work.section .work.content .worksD a .more-images img {
  margin-right: 5rem;
  width: 10rem;
  height: 7rem;
}
.homeL .frequency-wrapper .frequency {
  background-image: url(../../../../Assets/Images/Banner/map-banner.webp);
  padding: 5rem 15%;
  text-align: center;
}
@media (max-width: 1200px) {
  .homeL .frequency-wrapper .frequency {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .homeL .frequency-wrapper .frequency {
    padding: 5rem 2rem;
  }
}
.homeL .frequency-wrapper .frequency h1 {
  color: #000;
  margin-bottom: 2rem;
}
.homeL .frequency-wrapper .frequency p {
  margin-bottom: 4rem;
}
.homeL .frequency-wrapper .frequency .frequency-content .frequency-box img {
  width: 100%;
  margin-bottom: 4rem;
}
.homeL .frequency-wrapper .frequency .frequency-content .frequency-box h2 {
  color: #008f34;
}
.homeL .protect.section {
  padding: 5rem 13%;
  background-image: url(../../../../Assets/Images/Banner/pet-banner.webp);
}
@media (max-width: 1200px) {
  .homeL .protect.section {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .homeL .protect.section {
    padding: 5rem 2rem;
  }
}
.homeL .protect.section .protect.content {
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .homeL .protect.section .protect.content {
    flex-direction: column;
  }
}
.homeL .protect.section .protect.content .protect.info {
  width: 90%;
}
@media (max-width: 750px) {
  .homeL .protect.section .protect.content .protect.info {
    width: 100%;
  }
}
.homeL .protect.section .protect.content .protect.info h2 {
  margin-bottom: 1rem;
  color: #008f34;
  font-size: 4rem;
}
.homeL .protect.section .protect.content .protect.info h3 {
  margin-bottom: 2rem;
  color: black;
  font-size: 3rem;
}
.homeL .protect.section .protect.content .protect.info p {
  font-size: 2rem;
  line-height: 3.5rem;
}
.homeL .protect.section .protect.content .protect.img img {
  width: 100%;
  border-radius: 0.5rem;
}
.homeL .copyright.section {
  background-color: #eee;
}

.fixed-icon {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 200;
}

@media (max-width: 600px) {
  .fixed-icon {
    right: 10px;
    bottom: 10px;
  }
}
.Call-now-button {
  background-color: none;
  background-color: #045ad2;
  margin-top: 15px;
  border-radius: 5px;
  z-index: 10;
}

.Call-now-button .phone-icon1 {
  font-size: 3.4rem;
  margin: 10px;
  cursor: pointer;
  color: white;
  position: relative;
  z-index: 10;
}

.section {
  padding: 5rem 13%;
}
@media (max-width: 1200px) {
  .section {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .section {
    padding: 5rem 2rem;
  }
}

.fixed-top {
  position: fixed;
  background-color: #008f34;
  bottom: 2%;
  right: 2%;
  padding: 1.5rem;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.5s ease;
  cursor: pointer;
}
.fixed-top svg {
  color: white;
  width: 2rem;
  height: 2rem;
}
.fixed-top.active {
  opacity: 1;
}

.whatsapp-button {
  position: fixed;
  bottom: 7%;
  padding: 0.5rem 0.7rem;
  background-color: #25d365;
  border-radius: 5px;
  z-index: 10;
  left: 1%;
}
@media (max-width: 800px) {
  .whatsapp-button {
    left: 5%;
  }
}
.whatsapp-button i {
  font-size: 4rem;
  color: white;
}

.tube {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media (max-width: 900px) {
  .tube {
    display: flex;
  }
}
.tube iframe {
  width: 50rem;
  height: 35rem;
}
@media (max-width: 600px) {
  .tube iframe {
    width: 40rem;
    height: 35rem;
  }
}
.tube h2 {
  text-align: center;
  color: black;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}
.tube img {
  width: 100%;
}

.pressure-vid {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.couch-vid {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 0rem;
}
.couch-vid .tubeN {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.couch-vid .tubeN h2 {
  margin-bottom: 4rem;
}
.couch-vid .tubeN iframe {
  width: 70rem;
  height: 40rem;
}
@media (max-width: 650px) {
  .couch-vid .tubeN iframe {
    width: 40rem;
    height: 35rem;
  }
}/*# sourceMappingURL=Deep.css.map */