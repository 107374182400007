h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.contactMain {
  padding: 5rem 15%;
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .contactMain {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .contactMain {
    padding: 5rem 2rem;
  }
}
@media (max-width: 700px) {
  .contactMain {
    gap: 5rem;
    flex-direction: column;
  }
}
.contactMain .form-wrapper {
  width: 130%;
}
@media (max-width: 700px) {
  .contactMain .form-wrapper {
    width: 100%;
  }
}
.contactMain .form-wrapper h2 {
  font-size: 3.5rem;
  margin-bottom: 3rem;
}
.contactMain .form-wrapper h2 span {
  font-size: 3.5rem;
  color: #008f34;
}
.contactMain .form-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contactMain .form-wrapper form input {
  width: 100%;
  background-color: white;
  height: 2rem;
  padding: 2.5rem 0;
  font-size: 2rem;
  padding-left: 1rem;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
}
.contactMain .form-wrapper form textarea {
  padding: 1rem 0;
  background-color: white;
  height: 15rem;
  font-size: 2rem;
  padding-left: 1rem;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 5px;
}
.contactMain .form-wrapper form button {
  width: 18rem;
  background-color: transparent;
  padding: 0;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(1, 137, 177);
  border-radius: 4rem;
  cursor: pointer;
}
.contactMain .form-wrapper form button:hover {
  animation-name: btn1;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
.contactMain .form-wrapper form button span {
  font-size: 2.2rem;
  margin-right: 2rem;
  margin-left: 1rem;
  color: black;
}
.contactMain .form-wrapper form button img {
  width: 7rem;
  height: 5rem;
}
.contactMain .form-wrapper form .name {
  display: flex;
  gap: 2rem;
}
.contactMain .form-wrapper form .name .block {
  width: 100%;
}
.contactMain .form-wrapper form a {
  margin-top: 1rem;
  font-size: 1.8rem;
  color: blue;
}
.contactMain .info-wrapper {
  width: 70%;
}
@media (max-width: 700px) {
  .contactMain .info-wrapper {
    width: 100%;
  }
}
.contactMain .info-wrapper h2 {
  font-size: 3.5rem;
  margin-bottom: 3rem;
  color: #217e37;
}
.contactMain .info-wrapper .info-p {
  margin-bottom: 4rem;
  line-height: 3rem;
}
.contactMain .info-wrapper .info-box {
  margin-bottom: 3rem;
  display: flex;
  align-items: flex-start;
}
.contactMain .info-wrapper .info-box .icon svg {
  font-size: 3rem;
  color: #008f34;
  margin-right: 3rem;
}
.contactMain .info-wrapper .info-box .content span {
  font-size: 2.5rem;
  color: black;
}
.contactMain .info-wrapper .info-box .content p {
  margin-top: 1rem;
  font-size: 2.2rem;
  color: #008f34;
}

.melborne {
  padding: 5rem 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .melborne {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .melborne {
    padding: 5rem 2rem;
  }
}
.melborne h1 {
  text-align: center;
  color: #008f34;
  font-size: 3.5rem;
  margin-bottom: 4rem;
}
.melborne img {
  max-width: 70rem;
  padding: 1rem;
}
@media (max-width: 800px) {
  .melborne img {
    width: 100%;
  }
}/*# sourceMappingURL=Contact.css.map */