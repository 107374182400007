h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.gallery {
  padding: 5rem 12%;
}
@media (max-width: 1200px) {
  .gallery {
    padding: 5rem 7%;
  }
}
@media (max-width: 800px) {
  .gallery {
    padding: 5rem 2rem;
  }
}
.gallery .gallery-heading {
  text-align: center;
  margin-bottom: 4rem;
}
.gallery .gallery-heading h1 {
  color: #4c9824;
}
.gallery .gallery_wrapper {
  grid-gap: 2rem;
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
}
.gallery .gallery_wrapper .gallery_box .gallery_img img {
  cursor: pointer;
  width: 100%;
  min-height: 25rem;
}
.gallery .gallery_wrapper .gallery_box .gallery_title {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5rem;
  padding-top: 1rem;
  transition: 0.2s ease-in;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.gallery .gallery_wrapper .gallery_box .gallery_title h2 {
  color: #6ec1e4;
  font-size: 2.2rem;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
}/*# sourceMappingURL=Gallery.css.map */