h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }
  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
.thank {
  text-align: center;
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.thank h1 {
  color: #008f34;
  font-size: 5rem;
  margin-bottom: 0.5rem;
}
.thank .buttons {
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
}
.thank .buttons a {
  color: #008f34;
  font-size: 2.5rem;
  padding: 0.5rem 1rem;
  transition: 0.5s all ease;
}
.thank .buttons a:hover {
  background-color: #008f34;
  color: white;
}/*# sourceMappingURL=Thank.css.map */